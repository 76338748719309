.about{
    width: 100%;
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aboutflex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about_sec1_top{
    width: 48%;
}

.about_sec1{
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: center;
    color: #595959;
}
.about_reason{
    font-family: Inter;
    font-size: 45px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    color: var(--color-primary);
    margin-top: 16px;
}

.about_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: center;
    color:  #595959;
    margin-top: 40px;

}
.about_item{
    display: flex;
    gap: 18px;
    align-items: flex-start;
}
.about_item_title{
    //styleName: service-title;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
}
.about_item_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
}
.about_list{
    margin-top: 70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
    @media (max-width:760px){
        grid-template-columns: 1fr;
    }
}
.about_left{
    width: 45%;
    @media (max-width:760px){
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.aboutmarker{
    position: relative;
    .about_com{
        position: absolute;
        top: 40px;
        //styleName: subtitle;
        font-family: Karla;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.2px;
        letter-spacing: 3px;
        text-align: left;
        color: #595959;

    }
}
.about_marker_title{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
}
.about_marker_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
}
.about_count{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    @media (max-width:760px){
        flex-direction: column;
    }
}
.about_count_num{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: #F26622;
}
.about_count_text{
    //styleName: lead;
    font-family: Karla;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: var(--color-primary);
}
.about_count_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;

}
.markflex{
    display: flex;
    justify-content: space-between;
    width: 80%;
    @media (max-width:760px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.about_sec3{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 67px;

}

.about_team{
    width: 66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about_team_maker{
    position: relative;
}
.about_creative{
    position: absolute;
    top: 35px;
    left: 20%;
}
.name_about{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 45px;
}
.about_meet_team{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    color: var(--color-primary);
}
.faq_bg{
    background: url('../../assets/bgThick.svg')no-repeat center center/cover;
    padding-bottom: 100px;
}
.mtop{
    margin-top: 132px;
}