.pflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:760px){
        flex-direction: column;
    }
}
.proj_sec1{
    width: 100%;
    background: url('../../assets/bgThick.svg') no-repeat center center/cover;
    margin-top: 90px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 130px;
   
}
.phero_container{

    width: 100%;
    margin-left: 72px;
    @media (max-width:760px){
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
.proj_sec1_left{
    @media (max-width:760px){
       margin-top: 2rem;
    }
}
.proj_sec1_subtitle{
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: left;
    color: #595959;
    text-transform: uppercase;
    animation: zoomIn 1s ease-in-out;

}
.proj_sec1_title{
    //styleName: page-title;
    font-family: Outfit;
    font-size: 56px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    margin-top: 76px;
    width: 71%;
    animation: zoomIn 1s ease-in-out;
    @media (max-width:760px){
        margin-top: 1rem;
        font-size: 2rem;
    }
}
.proj_sec1_desc{
    //styleName: lead;
    font-family: Karla;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
    margin-top: 40px;
    width: 71%;
    animation: zoomIn 1s ease-in-out;
}
.image_container{
    width: 50%;
    height: 600px;
    @media (max-width:760px){
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center the ContentLoader horizontally */
        align-items: center;
    }
    
}

.servic_top{
    margin-bottom: 1rem;
}
@media (max-width: 600px) {
    .content-loader {
      width: 100%; /* Adjust the width of the ContentLoader component to fill the container */
      height: auto; /* Maintain aspect ratio */
    }
}