/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq-list {
    padding: 0;
  }
  
   .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
   .faq-list li+li {
    margin-top: 15px;
  }
  
   .faq-list li {
    
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
   .faq-list a {
    font-family: Outfit;
    font-weight: 600;
    font-size: 18px;
    line-height: 22.68px;
    letter-spacing: -0.56px;
    color: #1B1B1B;
    display: block;
    position: relative;
    outline: none;
    cursor: pointer;
  }
  
   .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #F26622;
  }
  
   .faq-list .icon-show,
   .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
   .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
   .faq-list .icon-show {
    display: none;
  }
  
   .faq-list a.collapsed {
    color: #595959;
  }
  
  .faq-list a.collapsed:hover {
    color: #F26622;
  }
  
  .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  .answer_item{
    font-family: Karla;
    font-weight: 400;
    font-size: 17px;
    line-height: 26.52px;
    letter-spacing: -0.85px;
    color: #595959;
  }