@import url('https://fonts.cdnfonts.com/css/outfit');

/*-------------------------
    Variables
-------------------------*/
:root {
  
  
  --color-primary: #1B1B1B;
  --color-secondary: #F26622;
  --color-grey:#8C8F97;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #595959;
  --color-dark: #1B1B1B;
  --color-dark-link: #F26622;
  --color-text-dark: #292930;
  --color-btn-light: #FEF0E9;
 
  
  
  
  
  
  --gradient-iphone: linear-gradient(108.97deg,#d9e9ba 1.84%, #a98dcfe3 80.67%);
 
  --border-light: 1px solid #E3E6E9;
  --border-lighter: 1px solid #ECF2F6;
  --border-dark: 1px solid var(--color-ship-gray);
  --border-gray: 1px solid var(--color-gray-4);
  
  --transition: all 0.3s ease-in-out;

  }
  .bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}


.color-gray-4 {
    color: var(--color-gray-4);
}


.opacity-50 {
    opacity: 0.5;
}
  
  
a {
    display: inline-block;
    text-decoration: none;
    transition: var(--transition);

    &:hover{
      color: var(--color-secondary);
    }

}
span {
    display: inline-block;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: block;
}

ul.list-style, 
ol.list-style {
    margin: -10px 0;
    
}
ul.list-style, 
ol.list-style li {
  margin: 10px 0;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}  

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  
  width: 77%;
  margin-left: auto;
  margin-right: auto;
  
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container {
      max-width: 100%;
      
  }
}

.landing_container{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}



body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;                                        
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}


@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*  */
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}
/*-------------------------
    Button
-------------------------*/
.btn-landing{
  width: fit-content;
  padding: 10px 12px;
  background: var(--color-primary);
  border-radius: 6px;
  font-style: normal;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  border-radius: 6px;
  color: var(--color-white);
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.btn{
  width: fit-content;
  padding: 10px 12px;
  background: var(--color-secondary);
  border-radius: 6px;
  font-style: normal;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  /* z-index: 1; */
  border-radius: 6px;
  color: var(--color-white);
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.btn::after{
  content: "";
  height: 300px;
  width: 300px;
  background-color: var(--color-btn-light);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* z-index: -1; */
}

.btn:hover{
  background-color: var(--color-secondary);
  border-color: var(--color-btn-light);
  color:var(--color-white) ;
  transform: scale(1.2);
}
.btn-landing::after{
  content: "";
  height: 300px;
  width: 300px;
  background-color: var(--color-primary-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;
}

.btn-landing:hover{
  background-color: #4e7fbb;
  border-color: #1e4778;
  color:var(--color-light) ;
  transform: scale(1.2);
}

.axil-btn {
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 20px;
  padding: 14px 40px;
  height: auto;
  text-align: center;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  color: #F7F8FA;

}
.btn-no-fill{
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 20px;
  height: auto;
  text-align: center;
  padding: 14px 23px;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  border: 1px solid #3E404A;  
  color:#161925;

}

.axil-btn.btn-borderd {
  border: var(--border-gray);
  color: var(--color-dark);
  background-color: transparent;
 
  
}

.btn-borderd:hover {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
}


.axil-btn.btn-fill-primary {
  background-color: var(--color-black);
  color: #F7F8FA;
  overflow: hidden;
  
 
}

.btn-fill-primary::after {
  content: "";
  height: 300px;
  width: 300px;
  background-color: var(--color-dark-link);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  z-index: -1;
}
.btn-fill-primary:hover {
  background-color: var(--color-link);
  border-color: var(--color-link);
  
}
.btn-fill-primary:hover::after {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.axil-btn.btn-fill-white {
  background-color: var(--color-white);
  box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
  
}
.btn-fill-white:hover {
  background-color: var(--color-accent1);
  color: var(--color-white);
}

.axil-btn.btn-large {
  padding: 23px 55px;
  
}
@media only screen and (max-width: 991px) {
  .axil-btn.btn-large{
    padding: 18px 35px;
  }
}
.axil-btn.btn-large-fluid {
  padding: 23px;
  width: 100%;
}
.axil-btn.btn-fluid {
  width: 100%;
}


/* Header */

.heros_t{
  position: relative;
  width: 100vw;
  max-width: 1440px;
  background: #FFFFFF;
  padding-bottom: 14px;
  z-index: 2;
  @media (max-width:1000px){
    z-index: 1;
  }
  
}

.top_social{
  width:100%;
  max-width: 1440px;
  height: 46.8px;
  padding: 12px 0px;
  gap: 10px;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width:760px){
    /* display: none; */
  }

}



.top_social_wraper{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.con_flex{
  display: flex;
  align-items: center;
  gap: 32px;
  position: relative;
  z-index: 100;
}

.top_social_items{
  display: flex;
  gap: 10px;
  align-items: center;
  
  @media (max-width:400px){
    gap: 0;
    justify-content: center;
  }
  & span{
    font-size: 14px;
    font-weight: 400;
    line-height: 17.64px;
    letter-spacing: -0.5600000023841858px;
    color: var(--color-white);
  }
}


.wrapper{
  width: 90%;
}
.social_style{
  cursor: pointer;
}

.turnoff{
  @media (max-width:1380px){
    display: none;
  }
}
.turnoffleft{
  @media (max-width:400px){
    display: none;
  }
}




