.project{
    width: 100vw;
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}



.psec2{
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 92px;
    align-items: center;
    justify-content: center;
    // padding-bottom: 131px;
}

.proj_wrapper{
    width: 90%;
    position: relative;

    @media (max-width:760px){
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
    
}

.proj_content{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    position: absolute;
    right: 0;
    top: 20%;
    width: 44%;
    @media (max-width:760px){
        position: relative;
        width: 100%;
    }
    .proj_content_inner{
        width: 76%;
        margin: 68px;
    }
}

.proj_content_title{
    //styleName: title-32;
    font-family: Outfit;
    font-size: 32px;
    font-weight: 600;
    line-height: 40.32px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: #FFFFFF;

}
.proj_content_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 44px;
    margin-bottom: 44px;
}

.social_style{
    cursor: pointer;
}
.pageNumbers{
    display: flex;
    margin-top: 92px;
    gap: 5px;
}
.page_num{
    width: 36px;
    height: 36px;
    background-color: #FFFFFF;
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: center;
    color: #595959;
    cursor: pointer;
    padding: 4px 13px;
    border: 1px solid #EBEBEB;
    margin-bottom: 190px;
    &.active{
        background: #F26622;
        color: #FFFFFF;
    }

}
.next_btn{
    width: 36px;
    height: 36px;
    background-color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EBEBEB;
}