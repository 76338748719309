.contact{
    margin-top: 132px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:760px){
        
            flex-direction: column;
    
    }
    

}
.contact_info{
    width: 90%;
    display: flex;
    justify-content: space-between;
    @media (max-width:760px){
        margin: 0;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
}
.contact_content{
    width: 22%;
    @media (max-width:760px){
        width: 100%;
    }
}
.contact_social{
    display: flex;
    gap: 8px;
    margin-top: 72px;
}
.contact_title{
    font-family: Outfit;
    font-size: 25px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
}
.contact_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
    margin-top: 32px;
    margin-bottom: 17px;
}  
.contact_phone{
    font-family: Karla;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
} 
.contact_form{
    width: 65%;
}

.form_container{
    width: 100%;
}
.input_double{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.contact_name{
    width: 60%;
}
.input_form_contact{
    border: none;
    border-bottom: 1px solid #EBEBEB;
    background: transparent;
    width: 100%;
}
.lblName{
    margin: 0;
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: left;
    color: #595959;
    margin-bottom: 50px;
}
.contact_email{

}
.contact_msg{
    width: 100%;
    margin-top: 41px;
}
.checkbox_input{
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 16px;
}
.save_input{
    margin: 0;
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
}
.contact_sec4{
    margin-top: 135px;
    width: 100%;
}



