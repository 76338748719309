$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';


.bottom{
    width: 100%;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .footer_top{
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1.5fr;
    
    @media (max-width:1000px){
        grid-template-columns:  1fr 1fr ;
        gap: 20px;
    }
    @media (max-width:760px){
        display:  flex;
        flex-direction: column;
        align-items: flex-start;
    }
  }
  .footer_contact{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .footer_contact_title{
    font-family: Karla;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.6px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #FFFFFF;
    @media (max-width:760px){
        font-size: 16px;
    }
  }
 
  
  .footer_contact_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #90939A;
  
  }
.footer_line{
width: 100%;
border: 1px solid #333333;
margin-top: 40px;

}
.footer_links{
    display: flex;
    gap: 152px;
    margin-top: 48px;
    @media (max-width:760px){
       flex-direction: column;
    }
    
}
.footer_company{
    width: 31%;
    @media (max-width:760px){
        width: 100%;
    }
    .footer_company_title{
        font-family: Outfit;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.68px;
        text-align: left;
        color: #FFFFFF;
    }

    .footer_company_desc{
        //styleName: text;
        font-family: Karla;
        font-size: 17px;
        font-weight: 400;
        line-height: 26.52px;
        letter-spacing: -0.8500000238418579px;
        text-align: left;
        color: #FFFFFF;
    }
}

.form_email{
    display: flex;
    
}
.input_email{
    border: none;
    background: transparent;
    color: #FFFFFF;
    outline: var(--color-secondary);
    border-bottom: 1px solid #90939A;
    padding: 10px;
    width: 75%;
}

.link_container{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 72px;
    @media (max-width:760px){
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:760px){
    .news_link{
       grid-column: span 2;
    }
}

.useful_links{
    display: flex;
    flex-direction: column;
}
.footer_link_header{
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    text-align: left;
    color: #FFFFFF;
}

.link_items{
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 28.56px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
}
.link_post{
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 1px solid #333333;
    & span{
        //styleName: meta;
        font-family: Karla;
        font-size: 13px;
        font-weight: 400;
        line-height: 20.28px;
        letter-spacing: -0.5600000023841858px;
        text-align: left;
        color: #90939A;
    }
}
.copywrite{
    color: #CCCCCC;
    margin-top: 32px;
}
.footer_img{
    @media (max-width:760px){
        height: 50px;
        width: 50px;
    }
  }