.landing{
  width: 100vw;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}





.land_sec1{
  width: 100%;
  background: url('../../assets/bg.svg') no-repeat center center/cover;
  margin-top: 90px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.sec1_wrapper{
  width: 90%;
  text-transform: uppercase;
  margin-top: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.subtitle{
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.2px;
  letter-spacing: 3px;
  text-align: center;
 
  animation: fadeInUp 1s ease-in-out;
}
.title{
  font-family: Outfit;
  font-size: 68px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.5600000023841858px;
  text-align: center;
  width: 50%;
  animation: fadeInUp 1s ease-in-out;
  @media (max-width:760px){
    font-size: 2rem;
    letter-spacing: 2px;
    width: 100%;
  }
}

.sec1_sec{
  display: flex;
  justify-content: space-between;
  margin-top: 71px;

  @media (max-width:760px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
.learn_more{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width:760px){
    width: 100%;
    margin-bottom: 2rem;
  }
  .learn_more_text{
    font-family: Karla;
    font-size: 24px;
    font-weight: 400;
    line-height: 37.44px;
    letter-spacing: -0.8500000238418579px;
    text-align: right;
    color: #595959;
    text-transform: lowercase;
    animation: fadeInLeft 3s ease-in-out;

  }
}
.more_text{
  color: var(--color-secondary);
  text-transform: capitalize;
  
}
.arrow_more{
  text-align: end;
  display: flex;
  align-items: end;
  margin-top: 300px;
  margin-left: auto;

  @media (max-width:760px){
    margin-top: 10px;
  }
}

.sec2{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 10px;
  @media (max-width:760px){
    
    grid-template-columns: 1fr 1fr ;
    row-gap: 2rem;
  }
  @media (max-width:560px){
    grid-template-columns: 1fr
    
  }
}

.sec2_item{

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #EBEBEB;
  .sec2_img{
    margin-top: 96px;
  }
  .sec2_title{
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    margin-top: 32px;
  }
  .sec2_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: center;
    color: #595959;
    margin-top: 16px;
    margin-bottom: 97px;

  }
}

.sec3{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 68px;
}
.sec3_flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width:760px){
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}
.sec3_left{
  width: 45%;
  @media (max-width:760px){
    width: 100%;
    
  }
}

.sec3_subtitle{
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.2px;
  letter-spacing: 3px;
  text-align: left;
  margin-bottom: 13px;
}
.sec3_title{
  //styleName: section-title;
  font-family: Outfit;
  font-size: 45px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  margin-bottom: 40px;
  @media (max-width:760px){
    font-size: 2rem;
  }
}
.sec3_desc{
  //styleName: text;
  font-family: Karla;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.52px;
  letter-spacing: -0.8500000238418579px;
  text-align: left;
  margin-bottom: 48px;
}

.sec4_container{
  width: 100%;
  background: url('../../assets/bgThick.svg') no-repeat center center/cover;
  margin-top: 68px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 200px;
  @media (max-width:760px){
    
    padding-bottom: 80px;
    
  }
  @media (max-width:500px){
    
    padding-bottom: 40px;
    
  }
  
}

.sec4{
  position: relative;
  width: 100%;
  background: #FFFFFF;
  padding-bottom: 100px;
}
.se4_top{
  margin-top: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sec4_subtitle{
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    color: #595959;

  }
  .title{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    margin-top: 19px;
    color: var(--color-primary);
  }
}
.sec4_list{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 48px;
  @media (max-width:760px){
    grid-template-columns: 1fr 1fr;
    
  }
  @media (max-width:500px){
    grid-template-columns: 1fr;
    
  }
}
.sec4_item{
  background-color:  #FFFFFF;
  padding: 50px 30px;
}
.sec4_pos{
  position: relative;
}
.sec4_t{
  //styleName: subtitle;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  & span{
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: left;
    color: #595959;
  }
  & .sec4_item_title{
    //styleName: service-title;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    margin: 0;

  }
}
.sec4_item_desc{
  //styleName: text;
  font-family: Karla;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.52px;
  letter-spacing: -0.8500000238418579px;
  text-align: left;
  color: #595959;
}
.sec4_img{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.sec5{
  width: 100%;
  @media (max-width:760px){
    display: none;
  }
  
  & .sec5_Project{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 74px;
  }
}
.projects_slide{
  width: 100%;
}
.project_slide{
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  
}
.proj{
  @media (max-width:760px){
    width: 70%;
  }
}
.sec6{
  width: 100%;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width:760px){
    margin-top: 3rem;
  }
}
.sec6_left{
  width: 40%;
  @media (max-width:760px){
    width: 100%;
  }
}
.sec6_faq{
  width: 200px;
  background: url('../../assets/faq.svg') no-repeat center center/cover;

}
.sec6_subtitle{
  //styleName: subtitle;
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.2px;
  letter-spacing: 3px;
  text-align: left;
  color: #595959;

}
.sec6_title{
  //styleName: section-title;
  font-family: Outfit;
  font-size: 45px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 40px;
  @media (max-width:760px){
    font-size: 2rem;
  }
}
.sec6_desc{
  //styleName: text;
  font-family: Karla;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.52px;
  letter-spacing: -0.8500000238418579px;
  text-align: left;
  color: #595959;

  & span{
    color: var(--color-secondary);
  }

}

.sec6_right{
  width: 48%;
  @media (max-width:760px){
    width: 100%;
  }
}
.sec6_pay{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom:1px solid #EBEBEB;
}

.sec6_pay_title{
  //styleName: service-title;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.68px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: var(--color-primary);
}
.sec6_right_desc{
  //styleName: text;
  font-family: Karla;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.52px;
  letter-spacing: -0.8500000238418579px;
  text-align: left;
  color: #595959;
  margin-top: 25px;

}
.sec7{
  width: 100%;
  background: url('../../assets/bgThick.svg') no-repeat center center/cover;
  margin-top: 68px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  padding-top: 130px;
  padding-bottom: 132px;
  @media (max-width:760px){
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.sec7_expert{
  width: 200px;
  background: url('../../assets/experts.svg') no-repeat center center/cover;
}
.sec7_left{
  width: 25%;
  @media (max-width:760px){
    width: 90%;
  }
}
.sec7_c{
  //styleName: subtitle;
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.2px;
  letter-spacing: 3px;
  text-align: left;
  color: #595959;
}
.sec7_team{
  //styleName: section-title;
  font-family: Outfit;
  font-size: 45px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: var(--color-primary);
}
.sec7_desc{
  //styleName: text;
  font-family: Karla;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.52px;
  letter-spacing: -0.8500000238418579px;
  text-align: left;
  color:  #595959;
  margin-top: 40px;

}

.img_arrow{
  display: flex;
  align-items: center;
  gap: 10px;
}

.sec7_right{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 29px;
  @media (max-width:760px){
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width:350px){
    grid-template-columns: 1fr ;
  }
}

.dflex{
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width:760px){
    flex-direction: column;
  }
}

.sec8{
  width: 100%;
  margin-top: 68px;
  background: #F26622;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec8_p{
  padding: 74px 0px 110px 0px;
  display: flex;
  align-items: center;
  gap: 54px;
}
.sec9{
  width: 100%;
  margin-top: 68px;
  position: relative;
  
}
.test_img{
  @media(max-width:760px){
    display: none;
  }
}
.testmonial{
  width: 45%;
  position: absolute;
  right: 0;
  top: 20%;
  
  background: #FFFFFF;
  box-shadow: 0 1px 6px 0 rgba(111, 125, 111, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:760px){
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    
    

  }
}
.test_inner{
  margin-top: 91px;
  margin-bottom: 68px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test_desc{
  //styleName: testimonial;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.8500000238418579px;
  text-align: center;
  color: var(--color-primary);
  margin-top: 18px;
}
.test_name{
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  letter-spacing: -0.5600000023841858px;
  text-align: center;
  color: var(--color-primary);
}
.test_title{
  //styleName: team-title;
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.7px;
  letter-spacing: -0.8500000238418579px;
  text-align: center;
}
.sec10{
  width: 100%;
  background: url('../../assets/bgThick.svg') no-repeat center center/cover;
  margin-top: 68px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 132px;
  
}

.sec10_news{
  width: 70%;
  height: 154px;
  margin-top: 70px;
  background: url('../../assets/news.svg') no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .sec10_c{
    margin-top: 80px;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: center;
    color: #595959;

  }
}
.sec10_center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec10_blog{
  //styleName: section-title;
  font-family: Outfit;
  font-size: 45px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: -0.5600000023841858px;
  text-align: center;
  color: var(--color-primary);
}
.news_img{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap: 40px;
  @media (max-width:760px){
    grid-template-columns: 1fr;
  }
}
.news_item{
  background: #FFFFFF;
  padding-bottom: 30px;
}

.blog_content{
  padding-left: 30px;
  width: 100%;
  
}
.blog_time{
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 31px;
}
.blog_cover_img{
  width: 100%;
}
.blog_date{
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.28px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: #595959;
}
.blog_text{
  color: #F26622;
}
.blog_title{
  //styleName: blog;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: var(--color-primary);
  margin-top: 20px;
  width: 80%;
}
.blog_desc{
  //styleName: meta;
  font-family: Karla;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.28px;
  letter-spacing: -0.5600000023841858px;
  text-align: left;
  color: #595959;
  margin-top: 16px;
}


