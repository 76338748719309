.error{
    margin-top: 90px;
    padding-bottom: 285px;
    width: 100%;
    margin-left:auto ;
    margin-right: auto;
    background: url('../../assets/bgThick.svg')no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}
.error_msg{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    color: var(--color-primary);
}
.btn_error{
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 50px;
}