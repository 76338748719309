.services{
    width: 100vw;
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sec_1{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
}
.service_bg{
    background: url('../../assets/servicesbg.svg')no-repeat center center/cover;
    height: 173px;
    width: 570px;
}

.service_list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width:760px){
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
}

.service_item{
    height: 476px;
    border-right: 1px solid #EBEBEB;
    background: #F6F6F6 ;
    @media (max-width:760px){
        height: auto;
    }
    .service_item_inner{
        margin: 40px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .service_item_content{
            display: flex;

            & span{
                //styleName: subtitle;
            font-family: Karla;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.2px;
            letter-spacing: 3px;
            text-align: left;
            color: var(--color-primary);
            }

            & .service_item_title{
                //styleName: service-title;
                font-family: Outfit;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                letter-spacing: -0.5600000023841858px;
                text-align: left;
                color: var(--color-primary);
            }

            & .service_item_desc{
                //styleName: text;
                font-family: PT Sans;
                font-size: 16px;
                font-weight: 400;
                line-height: 24.96px;
                text-align: left;
                color: #595959;
                margin-top: 24px;
            }
        }
    }
}

.flex_content{
    flex: 0.8;
    @media (max-width:760px){
        flex:none;
        
    }
}
.serv_arrow{
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.servic_readmore{
    font-family: Karla;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.2px;
    letter-spacing: -0.8500000238418579px;
    text-align: center;
    color: var(--color-secondary);
    margin: 0;
    text-transform: uppercase;
}

.sec_3{
    width: 100%;
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width:760px){
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }
}
.se3_pos{
    position: relative;
}
.img_s{
    position: absolute;
    left: 72px;
    top: 20%;
    @media (max-width:760px){
        position: relative;
        left: auto;
    }
}
.sec_3_invest{
    background: url('../../assets/bgThick.svg') no-repeat center center/cover;
    width: 58%;
    margin-left: auto;
    
    position: relative;
    display: flex;
    justify-content: end;
    @media (max-width:760px){
       width: 100%;
        padding: 0;
        margin: 0;
        justify-content: center;
        
    }
}
.sec_3_invest_inner{
    width: 68%;
    margin-top: 70px;
    margin-right: 72px;
    position: relative;
    margin-bottom: 189px;
}
.sec_3_about{
    position: absolute;
    top: 40px;
    left: 0;
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: left;
    color: #595959;
    @media (max-width:760px){
        position: relative;
        top: auto;
    }

}
.invest_invisible{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sec_3_rating{
    display: flex;
    gap: 48px;
    margin-top: 48px;
    @media (max-width:760px){
        flex-direction: column;
    }
}
.sec3_r_num{
    display: flex;
    gap: 30px;
}
.sec_3_r_text{
    //styleName: service-title;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
}
.sec_3_desc{
    margin-top: 40px;
}
.sec_3_title{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    @media (max-width:760px){
        font-size: 2rem;
    }

}

.sec_4{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.sec_4_process{
    margin: 0 auto;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.sec_4_p_subtitle{
    position: absolute;
    top: 40px;
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: center;
    color: #595959;
    text-transform: uppercase;
    
}

.sec_4_p_title{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: center;
    color: var(--color-primary);
}


.sec_4_steps{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    margin-top: 68px;
    gap: 20px;
    @media (max-width:760px){
        display: flex;
        flex-direction: column;
    }
}
.stepsItem{
    box-shadow: 0px 1px 6px 0px #6F7D6F26;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepsItem_inner{
    width: 76%;
    margin-top: 41px;
    margin-bottom: 40px;
}
.steps_subhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
}
.steps_subhead_t{
    //styleName: subtitle;
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.2px;
    letter-spacing: 3px;
    text-align: left;
    color: #F26622;
}
.steps_title{
    //styleName: service-title;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.68px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    margin-top: 10px;
}
.steps_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
    margin-top: 25px;
}
.stepsItem_container{
    position: relative;
    padding-bottom: 30px;
}
.circlStep{
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: auto;
}


.sec_5{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 200px;
    margin-bottom: 100px;

    @media (max-width:760px){
        flex-direction: column;
        margin-top: 2rem;
    }
    
}
.sec_5_left{
   width: 37%;
    margin-left: 72px;
    @media (max-width:760px){
        // width: 100%;
        width: 80%;
        margin-bottom: 2rem;
    }
}
.sec_5_connected{
    position: relative;

    .sec_5_conText{
        position: absolute;
        //styleName: subtitle;
        font-family: Karla;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.2px;
        letter-spacing: 3px;
        text-align: left;
        text-transform: uppercase;
        color: #595959;
        top: 50%;

    }
}
.sec_5_con{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    @media (max-width:760px){
        font-size: 2rem;
    }
}



.form_contact{
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.form_label{
    font-family: Karla;
    font-size: 13px;
    font-weight: 400;
    line-height: 20.28px;
    letter-spacing: 1.399999976158142px;
    text-align: left;
    color: #595959;
}

.form_input_contact{
    border: none;
    border-bottom: 1px solid #EBEBEB;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    outline: none;
}
.tech_main{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    
}

.tech_main_left{
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    
   
}
.tech_service_list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F9F9F9;
}
.tech_main_left_inner{
    width: 76%;
    margin-top: 48px;
    margin-bottom: 40px;
}
.tech_serv_title{
    font-family: Karla;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.7px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    margin-bottom: 24px;
    text-transform: uppercase;
   
}

.tech_serv_content{
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-bottom: 37px;
}
.tech_serv_num{
    display: flex;
    gap: 5px;
    cursor: pointer;
    &:hover{
        color: #F26622;
    }

    & span{
        //styleName: text;
        font-family: Karla;
        font-size: 17px;
        font-weight: 400;
        line-height: 26.52px;
        letter-spacing: -0.8500000238418579px;
        text-align: left;
        color: #595959;
        @media (max-width:760px){
            font-size: 14px;
        }
        @media (max-width:560px){
            font-size: 12px;
        }
    }
}

.tech_serv_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
    @media (max-width:560px){
        font-size: 12px;
    }
}
.tech_serv_phone{
    display: flex;
    gap: 16px;
    margin-top: 38px;
    align-items: center;
    @media (max-width:760px){
        flex-direction: column;
    }
}
.tech_serv_phone_text{
    font-family: Karla;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.04px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #F26622;
    margin: 0;
    @media (max-width:760px){
        font-size: 12px;
    }
}
.serv_content_right{
    width: 63%;
}
.serv_step{
    font-family: Karla;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.04px;
    letter-spacing: 3px;
    text-align: left;
    color: var(--color-secondary);
    margin-bottom: 22px;
}
.serv_cont1{

}
.servcon_title{
    //styleName: section-title;
    font-family: Outfit;
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    @media (max-width:760px){
        font-size: 2rem;
    }
}
.servcon_desc{
    //styleName: text;
    font-family: Karla;
    font-size: 17px;
    font-weight: 400;
    line-height: 26.52px;
    letter-spacing: -0.8500000238418579px;
    text-align: left;
    color: #595959;
    @media (max-width:760px){
        font-size: 1rem;
    }
}
.techflex{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.serv_group{
    background: #F9F9F9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F9F9F9;
    .serv_group_inner{
        width: 90%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
.serv_group_title{
    font-family: Karla;
    font-size: 18px;
    font-weight: 700;
    line-height: 34.65px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);

}
.serv_group_list{

    & li{
        font-family: Karla;
        font-size: 18px;
        font-weight: 400;
        line-height: 34.65px;
        letter-spacing: -0.5600000023841858px;
        text-align: left;
        color: var(--color-primary);
    }
}
.invert_spec{
    font-family: Karla;
    font-size: 24px;
    font-weight: 700;
    line-height: 46.2px;
    letter-spacing: -0.5600000023841858px;
    text-align: left;
    color: var(--color-primary);
    margin-bottom: 20px;

}
.serv_pro{
    @media (max-width:760px){
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}